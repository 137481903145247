$font-family-sans-serif: "Proxima Nova", Tahoma, Helvetica, Arial, sans-serif;
$body-color: $colorBody !default;

$headings-font-family: "Proxima Nova", Tahoma, Helvetica, Arial, sans-serif !default;
$headings-font-weight: 600 !default;
$headings-color: $colorHeading !default;

/*
	* Overriding boostraps spacer utilies
	* Spacers mutliples of one root element unit defined as the font size set in html (1 rem)
	* Defined as 1 rem * spacer size key
 */
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    0_25: $spacer * 0.25,
    0_5: $spacer * 0.5,
    0_75: $spacer * 0.75,
    1: $spacer,
    1_25: $spacer * 1.25,
    1_5: $spacer * 1.5,
    2: (
      $spacer * 2
    ),
    2_5: (
      $spacer * 2.5
    ),
    3: (
      $spacer * 3
    ),
    3_5: (
      $spacer * 3.5
    ),
    4: (
      $spacer * 4
    ),
    4_5: (
      $spacer * 4.5
    ),
    5: (
      $spacer * 5
    ),
    6: (
      $spacer * 6
    ),
    7: (
      $spacer * 7
    ),
    7_5: (
      $spacer * 7.5
    ),
    8: (
      $spacer * 8
    ),
    9: (
      $spacer * 9
    ),
    10: (
      $spacer * 10
    )
  ),
  $spacers
);
