.status-message {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-out;

  &.status-message-enter-active,
  &.status-message-enter-done.no-hide {
    opacity: 1;
    max-height: 200px;
    transition: all 0.3s ease-in;
  }

  .large {
    position: relative;
    top: 2px;
  }
}
