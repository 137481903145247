@import "../variables.scss";

.Checkbox {
  .check {
    float: left;
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input {
    // Hide default checkbox
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 3px;
    left: 0;
    width: 20px;
    height: 20px;

    &:checked ~ .checkmark {
      // Checked styles
      background-color: $merchantButtonColor;
      border-color: $merchantButtonColor;

      &:after {
        // Show checkmark
        opacity: 1;
      }
    }
  }

  .checkmark {
    // Custom checkbox
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 3px;
    transition: all 0.15s ease-in-out;

    &:after {
      // Display checkmark
      display: block;
      opacity: 0;
      content: " ";
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      height: 10px;
      width: 6px;
      border-bottom: 2px solid $merchantButtonFontColor;
      border-right: 2px solid $merchantButtonFontColor;
      position: relative;
      left: 5px;
      top: 1px;
      transition: all 0.15s ease-in-out;
    }
  }

  .description {
    overflow: hidden;
  }
}

